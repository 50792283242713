<template>
  <div class="range-picker-advanced">
    <popover
      :open.sync="dropdown"
      :disabled="disabled"
      :should-close="shouldClose"
      fixed
      :placement="placement"
    >
      <button
        slot="trigger"
        :class="{ 'button': true, 'is-small': small, 'is-static': disabled }"
        type="button"
      >
        <octicon :icon="calendar" />
        <span>{{ text }}</span>
      </button>
      <tabs
        :show.sync="active"
        :theme="$_ui_theme_tabs"
        :style="{ minWidth: time ? '350px' : '200px' }"
      >
        <tab :title="$t('picker.presets')">
          <div class="columns">
            <div
              v-for="(chunk, index) in suggestionsChunks"
              :key="index"
              class="column"
            >
              <a
                v-for="s in chunk"
                :key="s.key"
                :class="{'dropdown-item': true, 'is-active': suggestion && s.key === suggestion.key}"
                href="#"
                @click.prevent="choose(s)"
              >{{ $t('picker.' + s.key) }}</a>
            </div>
          </div>
        </tab>
        <tab :title="$t('picker.range')">
          <input
            ref="rp"
            v-rangepicker="dates"
            type="text"
            class="input"
          >
        </tab>
      </tabs>
    </popover>
  </div>
</template>
<script>
import Flatpickr from 'flatpickr'
import { startOfDay, endOfDay, startOfMonth, endOfMonth, subMinutes, subHours, subDays, subMonths } from 'date-fns'
import { calendar } from 'octicons-vue'
import { Tabs, Tab } from '@cyradar/ui'
import { chunk } from '@/utils'

export default {
  i18n: {
    dateTimeFormats: {
      en: {
        short: {
          year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
        }
      },
      vi: {
        short: {
          year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
        }
      }
    },
    messages: {
      en: {
        picker: {
          pick: 'Pick a range',
          presets: 'Presets',
          range: 'Date & Time Picker',
          day0: 'Today',
          'day-1': 'Yesterday',
          'day-7': 'Last 7 days',
          'day-30': 'Last 30 days',
          month0: 'This month',
          'month-1': 'Last month',
          'minute-1': 'Last minute',
          'minute-5': 'Last 5 minutes',
          'minute-15': 'Last 15 minutes',
          'minute-30': 'Last 30 minutes',
          'hour-1': 'Last hour',
          'hour-4': 'Last 4 hours',
          'hour-12': 'Last 12 hours',
          'hour-24': 'Last 24 hours'
        }
      },
      vi: {
        picker: {
          pick: 'Khoảng thời gian',
          presets: 'Gợi ý',
          range: 'Ngày và Giờ',
          day0: 'Hôm nay',
          'day-1': 'Hôm qua',
          'day-7': '7 ngày trước',
          'day-30': '30 ngày trước',
          month0: 'Tháng này',
          'month-1': 'Tháng trước',
          'minute-1': '1 phút trước',
          'minute-5': '5 phút trước',
          'minute-15': '15 phút trước',
          'minute-30': '30 phút trước',
          'hour-1': '1 giờ trước',
          'hour-4': '4 giờ trước',
          'hour-12': '12 giờ trước',
          'hour-24': '24 giờ trước'
        }
      }
    }
  },
  components: { Tabs, Tab },
  directives: {
    rangepicker: {
      bind (el, binding, vnode) {
        el.picker = new Flatpickr(el, {
          defaultDate: binding.value,
          mode: 'range',
          enableTime: vnode?.context?.time,
          time_24hr: true,
          onChange (val) {
            if (!val || val.length !== 2) {
              return
            }

            vnode.context.suggestion = null
            vnode.context.dates = [
              val[0],
              val[1]
            ]
          }
        })
      },
      unbind (el) {
        if (!el.picker) {
          return
        }

        el.picker.destroy()
      }
    }
  },
  props: {
    lookup: {
      type: String,
      default: ''
    },
    range: {
      type: Array,
      default () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    time: {
      type: Boolean,
      default: false
    },
    minHour: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom-end'
    }
  },
  data () {
    return {
      active: 0,
      dropdown: false,
      suggestion: null,
      dates: null,
      now: new Date()
    }
  },
  computed: {
    text () {
      if (this.type === 'presets') {
        return this.$t(`picker.${this.suggestion.key}`)
      }

      if (this.type === 'range' && !this.dates) {
        return this.$t('picker.pick')
      }

      if (this.type === 'range') {
        return this.$d(this.dates[0], 'short') + ' - ' + this.$d(this.dates[1], 'short')
      }

      return this.$t('picker.pick')
    },
    type () {
      if (this.suggestion && this.suggestion.key) {
        return 'presets'
      }

      if (this.dates && this.dates.length === 2) {
        return 'range'
      }

      return ''
    },
    calendar () {
      return calendar
    },
    suggestions () {
      const now = this.now
      const yesterday = subDays(now, 1)
      const daysAgo7 = subDays(now, 7)
      const daysAgo30 = subDays(now, 30)
      const lastMonth = subMonths(now, 1)

      return [
        { key: 'day0', value: [startOfDay(now), endOfDay(now)] },
        { key: 'day-1', value: [startOfDay(yesterday), endOfDay(yesterday)] },
        { key: 'day-7', value: [startOfDay(daysAgo7), endOfDay(now)] },
        { key: 'day-30', value: [startOfDay(daysAgo30), endOfDay(now)] },
        { key: 'month0', value: [startOfMonth(now), endOfMonth(now)] },
        { key: 'month-1', value: [startOfMonth(lastMonth), endOfMonth(lastMonth)] },
        { key: 'minute-1', value: [subMinutes(now, 1), now] },
        { key: 'minute-5', value: [subMinutes(now, 5), now] },
        { key: 'minute-15', value: [subMinutes(now, 15), now] },
        { key: 'minute-30', value: [subMinutes(now, 30), now] },
        { key: 'hour-1', value: [subHours(now, 1), now] },
        { key: 'hour-4', value: [subHours(now, 4), now] },
        { key: 'hour-12', value: [subHours(now, 12), now] },
        { key: 'hour-24', value: [subHours(now, 24), now] }
      ].filter(item => {
        return this.minHour ? !item.key.startsWith('minute') : true
      })
    },
    suggestionsChunks () {
      return chunk(this.suggestions, 2, true)
    },
    containsStrings () {
      if (!this.range || !this.range.length) {
        return false
      }

      return typeof this.range[0] === 'string'
    }
  },
  watch: {
    lookup: {
      immediate: true,
      handler: 'setSuggestionFromLookup'
    },
    range: {
      immediate: true,
      handler (val) {
        if (!val) {
          return
        }

        if (!this.containsStrings) {
          this.dates = val
          return
        }

        this.dates = val.map(x => new Date(x))
      }
    },
    suggestion: {
      immediate: true,
      handler (val) {
        if (!val) {
          this.$emit('update:lookup', '')
          return
        }

        this.$emit('update:lookup', val.key)
        this.dates = val.value
      }
    },
    dates: {
      immediate: true,
      handler (val) {
        this.$emit('update:range', val)
      }
    }
  },
  methods: {
    reloadSuggestions () {
      this.now = new Date()
      this.setSuggestionFromLookup()
    },
    setSuggestionFromLookup () {
      const item = this.suggestions.find(suggestion => suggestion.key === this.lookup)
      if (!item) {
        this.suggestion = null
        return
      }

      this.suggestion = item
    },
    choose (suggestion) {
      if (!suggestion) {
        return
      }

      this.suggestion = suggestion
      this.dropdown = false
    },
    shouldClose (e) {
      if (this.$el.contains(e.target)) {
        const classes = e.target.classList
        if (classes.contains(this.$_ui_theme_tabs.item)) {
          return false
        }

        return true
      }

      if (!this.$refs.rp || !this.$refs.rp.picker || !this.$refs.rp.picker.calendarContainer) {
        return true
      }

      if (this.$refs.rp.picker.calendarContainer.contains(e.target)) {
        return false
      }

      if (e.target.tagName === 'BODY' && this.$refs.rp.picker.isOpen) {
        // sometimes clicking into the input fires events whose target is body
        return false
      }

      return true
    }
  }
}
</script>
<style lang="scss">
.range-picker-advanced {
  .button {
    justify-content: start;
  }

  .popover__container {
    width: 100%;
  }

  .popover__ref {
    width: 100%;
    flex-direction: column;
    button {
      width: 100%;
    }
  }

  .popover__content {
    padding: 0;
  }

  .dropdown-menu {
    min-width: 30rem;
  }
}
</style>
