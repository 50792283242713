<template>
  <section class="section">
    <div class="level">
      <h2 class="title level-left">
        {{ $t('route.policies') }}
      </h2>
    </div>
    <tabs :theme="$_ui_theme_tabs" :show.sync="selectedTabIndex">
      <tab v-for="policyTab in policyTabs" :key="policyTab" :title="$t(policyTab)">
        <div class="level">
          <h2 class="title level-left" />
          <p>
            <button
              class="button is-primary"
              @click.prevent="create"
            >
              <octicon :icon="plus" /> <span>{{ $t('create') }}</span>
            </button>
          </p>
        </div>

        <box>
          <data-table
            :ref="`${selectedTab}-table`"
            :items="items"
            :theme="$_ui_theme_tables"
            :pagination="[]"
            :disable-filtering="true"
          >
            <data-column
              field="matching"
              :label="$t('policy-matching')"
            />
            <data-column
              field="description"
              :label="$t('description')"
            />
            <data-column
              :label="$t('actions')"
              :sortable="false"
            >
              <template slot-scope="props">
                <button
                  v-show="can(`update:policies:${props.item.id}`)"
                  class="button is-small is-warning is-light mr-2"
                  @click.prevent="edit(props.item)"
                >
                  <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
                </button>

                <button
                  v-show="can(`update:policies:${props.item.id}`)"
                  class="button is-small is-danger is-light"
                  @click.prevent="destroy(props.item)"
                >
                  <octicon :icon="trashcan" /> <span>{{ $t('delete') }}</span>
                </button>
              </template>
            </data-column>
          </data-table>
        </box>
      </tab>
    </tabs>
    <modal v-if="item" :show.sync="modal" content-width="800px">
      <form @submit.prevent="submit">
        <box>
          <div slot="header">
            {{ selectedTab === 'whitelist' ? $t('newWhitelistPolicy') : $t('newBlacklistPolicy') }}
          </div>

          <div class="field">
            <label class="label">{{ $t('policy-matching') }}</label>
            <div class="control">
              <input
                v-model="item.matching" class="input" type="text"
                required
              >
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t('description') }}</label>
            <div class="control">
              <input
                v-model="item.description" class="input" type="text"
              >
            </div>
          </div>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </box>
      </form>
    </modal>
  </section>
</template>

<script>
import { DataTable, DataColumn } from 'vue-teible'
import { pencil, plus, trashcan } from 'octicons-vue'
import { Tabs, Tab, Modal } from '@cyradar/ui'

export default {
  components: { Tabs, Tab, DataTable, DataColumn, Modal },
  data () {
    return {
      selectedTabIndex: 0,
      modal: false,
      item: null
    }
  },
  computed: {
    policyTabs () {
      return ['whitelist', 'blacklist']
    },
    selectedTab () {
      return this.policyTabs[this.selectedTabIndex]
    },
    plus () {
      return plus
    },
    trashcan () {
      return trashcan
    },
    pencil () {
      return pencil
    },
    language () {
      return this.$store.state.ui.language
    }
  },
  watch: {
    language (v, o) {
      if (!o) {
        return
      }

      if (!this.$refs.table) {
        return
      }

      this.$nextTick(() => {
        this.loadTablesSlots()
      })
    }
  },
  methods: {
    loadTablesSlots () {
      const tableRefName = `${this.selectedTab}-table`
      if (!this.$refs || !this.$refs[tableRefName] || !this.$refs[tableRefName][0] || !this.$refs[tableRefName][0].loadSlots) {
        return
      }
      this.$refs[tableRefName][0].loadSlots()
    },
    reloadTablesItems () {
      const tableRefName = `${this.selectedTab}-table`
      if (!this.$refs || !this.$refs[tableRefName] || !this.$refs[tableRefName][0] || !this.$refs[tableRefName][0].reloadItems) {
        return
      }
      this.$refs[tableRefName][0].reloadItems()
    },
    closeModal () {
      this.modal = false
    },
    submit () {
      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    resetItem () {
      this.item = {
        matching: '',
        description: ''
      }
    },
    create () {
      this.resetItem()
      this.modal = true
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
    },
    store () {
      if (!this.item) {
        return
      }
      const data = {
        ...this.item,
        type: this.selectedTab
      }

      return this.$http.post('/api/v1/filter-rules', data)
        .then(body => {
          if (!body) {
            return
          }

          this.reloadTablesItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Setting Created'
          })

          this.modal = false
          this.resetItem()
        })
    },
    update () {
      if (!this.item) {
        return
      }

      return this.$http.patch(`/api/v1/filter-rules/${this.item.id}`, this.item)
        .then(body => {
          if (!body) {
            return
          }

          this.reloadTablesItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Setting Updated'
          })

          this.modal = false
          this.resetItem()
        })
    },
    destroy (x) {
      return this.$http.delete(`/api/v1/filter-rules/${x.id}`)
        .then(body => {
          if (!body) {
            return
          }

          this.reloadTablesItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Setting Deleted'
          })
        })
    },
    items () {
      return this.$http.get(`/api/v1/filter-rules/${this.selectedTab}`)
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }
          return {
            total: data.data.length || 0,
            items: data.data
          }
        })
    }
  }
}
</script>
<style lang="scss">
.control-confidence {
  position: relative;

  .button {
    position: absolute;
    top: 0.4em;
    right: 3em;
  }
}
</style>
