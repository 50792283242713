<template>
  <popover
    class="daypicker" placement="bottom"
    fixed :disabled="disabled"
  >
    <div slot="trigger" class="field">
      <div class="control has-icons-right">
        <input
          type="text" :class="{input: true, 'is-small': small}"
          readonly :value="pad(value, 2)"
          :disabled="disabled"
        >
        <span class="icon is-right">
          <octicon :icon="calendar" />
        </span>
      </div>
    </div>
    <div
      v-for="(chunk, x) in optionsChunked" :key="x"
    >
      <a
        v-for="item in chunk" :key="item"
        :class="{'dropdown-item': true, 'is-active': isActive(item)}"
        href="#" @click.prevent="toggle(item)"
      >{{ pad(item, 2) }}</a>
    </div>
  </popover>
</template>
<script>
import { calendar } from 'octicons-vue'
import { chunk } from '@/utils'

function pad (num, size) {
  let s = num + ''
  while (s.length < size) s = '0' + s
  return s
}

export default {
  name: 'DayPicker',
  props: {
    value: {
      type: Number,
      default () {
        return 1
      }
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      items: [...Array(31).keys()].map(x => x + 1)
    }
  },
  computed: {
    options () {
      return this.items
    },
    optionsChunked () {
      return chunk(this.options, 7)
    },
    calendar () {
      return calendar
    }
  },
  methods: {
    pad,
    toggle (index) {
      this.$emit('update:value', index)
    },
    isActive (index) {
      return index === this.value
    }
  }
}
</script>
<style lang="scss">
.daypicker {
  .popover {
    max-width: none;
  }

  a.dropdown-item {
    display: inline;
    padding: 0.25rem 1rem;
    text-align: center;
  }
}
</style>
