<template>
  <section class="section">
    <div class="level">
      <h2 class="title level-left">
        {{ $t('route.reports-all') }}
      </h2>
      <p>
        <button
          class="button is-primary"
          @click.prevent="create"
        >
          <octicon :icon="plus" /> <span>{{ $t('create') }}</span>
        </button>
      </p>
    </div>
    <box>
      <data-table
        ref="table"
        :items="items"
        :theme="$_ui_theme_tables"
        :per-page="20"
        sort-desc
        sort-by="created_at"
      >
        <data-column
          field="created_at"
          :label="$t('time')"
        >
          <template slot-scope="{ value }">
            <span>{{ value | dmyhs }}</span>
          </template>
        </data-column>
        <data-column
          field="name"
          :label="$t('name')"
        />
        <data-column
          field="repeat"
          :label="$t('reports.repeat')"
        >
          <template slot-scope="props">
            <span>{{ repeatFromItem(props.item) ? $t('reports.repeat_' + repeatFromItem(props.item)): '' }}</span>
          </template>
        </data-column>
        <data-column
          field="template"
          :label="$t('reports.template')"
        >
          <template slot-scope="{ value }">
            <span class="px-2 py-1 tag is-info is-light is-uppercase mr-1">{{ value }}</span>
          </template>
        </data-column>
        <data-column
          field="status"
          :label="$t('status')"
        >
          <template slot-scope="props">
            <span
              :class="{
                'px-2 py-1 tag is-light is-uppercase mr-1 is-uppercase': true,
                'is-info': props.value === STATUS_ENQUEUED,
                'is-info': props.value === STATUS_PROCESSING,
                'is-warning': props.value === STATUS_CANCELED,
                'is-danger': props.value === STATUS_ERROR,
                'is-success': props.value === STATUS_COMPLETE,
              }"
              v-text="props.value"
            />
          </template>
        </data-column>
        <data-column
          :label="$t('actions')"
          :sortable="false"
          width="200px"
        >
          <template slot-scope="props">
            <button
              class="button is-small is-primary is-light mr-2"
              @click.prevent="show(props.item)"
            >
              <octicon :icon="eye" /> <span>{{ $t('details') }}</span>
            </button>
            <button
              class="button is-small is-danger is-light mr-2"
              @click.prevent="destroy(props.item)"
            >
              <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
            </button>
          </template>
        </data-column>
      </data-table>
    </box>

    <modal v-if="item" :show.sync="modal" content-width="800px">
      <form @submit.prevent="submit">
        <box>
          <div slot="header">
            <span v-if="!item.id">{{ $t('newReport') }} <b v-if="item.name">-</b> </span> {{ item.name }}
          </div>

          <template v-if="step === 1">
            <div class="field">
              <label class="label">{{ $t('name') }}</label>
              <div class="control">
                <input
                  v-model="item.name" class="input" type="text"
                  required placeholder="Your Report" :disabled="item.id"
                >
              </div>
            </div>

            <div class="columns mb-0">
              <div class="column">
                <div class="field">
                  <label class="label">{{ $t('reports.template') }}</label>
                  <div class="select is-fullwidth">
                    <select
                      v-model="item.template" :disabled="item.id"
                      required
                    >
                      <option disabled>
                        {{ $t('selectDefault') }}
                      </option>
                      <option v-for="t in ReportTemplates" :key="t.value" :value="t.value">
                        {{ t.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="item.status" class="column">
                <div class="field">
                  <label class="label">{{ $t('status') }}</label>
                  <div class="control">
                    <input
                      v-model="item.status" class="input is-uppercase" type="text"
                      required :disabled="item.id"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div v-if="item.channels" class="field">
              <label class="label">{{ $t('channels') }}</label>
              <div v-for="c in ReportChannels" :key="c">
                <label class="checkbox mr-4">
                  <input
                    v-model="itemChannelsArr" :value="c" :disabled="item.id"
                    type="checkbox"
                  >
                  <span class="ml-1 is-uppercase">{{ c }}</span>
                </label>
                <input
                  v-show="itemChannels[c] !== undefined" v-model="itemChannels[c]" :disabled="item.id"
                  class="input mb-2" type="text"
                >
              </div>
            </div>

            <label class="label">{{ $t('reports.repeat') }}</label>
            <div class="field has-addons">
              <p class="control is-expanded">
                <span class="select is-fullwidth">
                  <select v-model="itemRepeat">
                    <option v-for="(r, k) in ReportRepeats" :key="k" :value="k">{{ $t('reports.repeat_' + k) }}</option>
                  </select>
                </span>
              </p>
              <p v-if="item.repeat === 'Weekly'" class="control">
                <weekday-picker :value.sync="item.scheduled_weekday" />
              </p>
              <p v-if="item.repeat === 'Monthly'" class="control">
                <day-picker :value.sync="item.scheduled_day" />
              </p>
              <p v-if="item.repeat" class="control">
                <time-picker :time.sync="item.scheduled_time" />
              </p>
            </div>

            <template v-if="!item.repeat">
              <div class="field">
                <label class="label">{{ $t('queryRange') }}</label>
                <range-picker
                  ref="rangepicker" :range.sync="itemDates" min-hour
                  placement="bottom" :disabled="!!item.id"
                />
              </div>
            </template>

            <div v-if="item.files" class="field">
              <label class="label">{{ $t('files') }}</label>
              <ul style="list-style: disc; margin-left: 2em;">
                <li v-for="f in item.files" :key="f">
                  <a :href="'/files/' + f" target="_blank">{{ f }}</a>
                </li>
              </ul>
            </div>
          </template>
          <template v-if="step === 2">
            <template v-if="item.template === ReportThreatOverview">
              <div style="position: relative;">
                <hr>

                <div style="position: absolute; top: -1em; left: 0; right: 0;">
                  <div class="buttons has-addons is-centered are-small">
                    <button type="button" :class="{ button: true, 'is-primary': !itemFiltersAnd }" @click.prevent="itemFiltersAnd = false">
                      <span style="min-width: 3em">OR</span>
                    </button>
                    <button type="button" :class="{ button: true, 'is-primary': itemFiltersAnd}" @click.prevent="itemFiltersAnd = true">
                      <span style="min-width: 3em">AND</span>
                    </button>
                  </div>
                </div>
              </div>

              <div v-for="(f, index) in itemFilters.filters" :key="index" class="field has-addons">
                <p class="control">
                  <span class="select">
                    <select :value="f.field" readonly disabled>
                      <option v-for="(field, name) in ReportThreatOverviewFields" :key="name" :value="name">{{ field.label }}</option>
                    </select>
                  </span>
                </p>
                <p class="control">
                  <span class="select">
                    <select :value="f.operator" :disabled="item.id">
                      <option v-for="operator in operators" :key="operator.value" :value="operator.value">{{ operator.label }}</option>
                    </select>
                  </span>
                </p>
                <div class="control is-expanded">
                  <input
                    :value="f.value" class="input" type="text"
                    :disabled="item.id" @input="updateFilter(f, $event.target.value)"
                  >
                </div>
              </div>
            </template>
            <p v-else>
              Invalid Template
            </p>
          </template>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
            <div v-if="step > 1" class="control">
              <button type="button" class="button is-link is-light" @click.prevent="step--">
                {{ $t('prev') }}
              </button>
            </div>
            <div v-if="step < 2" class="control">
              <button type="button" class="button is-link" @click.prevent="step++">
                {{ $t('next') }}
              </button>
            </div>
            <div v-if="step === 2 && !item.id" class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
          </div>
        </box>
      </form>
    </modal>
  </section>
</template>

<script>
import { DataTable, DataColumn } from 'vue-teible'
import { eye, check, plus, x } from 'octicons-vue'
import { Modal, TimePicker, WeekdayPicker } from '@cyradar/ui'
import RangePicker from '@/components/RangePicker'
import DayPicker from '@/components/DayPicker'
import { ReportThreatOverview } from '@/store'

const AND = 'and'
const OR = 'or'
const STATUS_ENQUEUED = 'enqueued'
const STATUS_PROCESSING = 'processing'
const STATUS_CANCELED = 'canceled'
const STATUS_ERROR = 'error'
const STATUS_COMPLETE = 'complete'
const REPEAT_DAILY = 'Daily'
const REPEAT_WEEKLY = 'Weekly'
const REPEAT_MONTHLY = 'Monthly'

export default {
  components: { DataTable, DataColumn, Modal, RangePicker, TimePicker, WeekdayPicker, DayPicker },
  data () {
    return {
      processing: false,
      modal: false,
      item: null,
      itemChannels: {},
      step: 1
    }
  },
  computed: {
    ReportChannels () {
      return ['email', 'sms']
    },
    ReportRepeats () {
      return {
        no: {
          value: ''
        },
        daily: {
          value: REPEAT_DAILY
        },
        dailyRound: {
          value: REPEAT_DAILY,
          rounding: true
        },
        weekly: {
          value: REPEAT_WEEKLY
        },
        weeklyRound: {
          value: REPEAT_WEEKLY,
          rounding: true
        },
        monthly: {
          value: REPEAT_MONTHLY
        },
        monthlyRound: {
          value: REPEAT_MONTHLY,
          rounding: true
        }
      }
    },
    ReportTemplates () {
      return [{
        label: 'Threat Overview',
        value: this.ReportThreatOverview
      }]
    },
    ReportThreatOverview () {
      return ReportThreatOverview
    },
    ReportThreatOverviewFields () {
      return {
        id: {
          label: 'ID'
        },
        conn_id: {
          label: 'Connection ID'
        },
        src_host: {
          label: 'Source'
        },
        dst_host: {
          label: 'Destination'
        },
        origin: {
          label: 'Origin'
        },
        tactics: {
          label: 'Tactics',
          array: true
        },
        techniques: {
          label: 'Techniques',
          array: true
        },
        sensor_id: {
          label: 'Sensor'
        }
      }
    },
    operators () {
      return [{
        label: '=',
        value: 'eq'
      }, {
        label: '!=',
        value: 'not_eq'
      }, {
        label: '>',
        value: 'gt'
      }, {
        label: '≥',
        value: 'gt_or_eq'
      }, {
        label: '<',
        value: 'lt'
      }, {
        label: '≤',
        value: 'lt_or_eq'
      }, {
        label: 'Include',
        value: 'intersect'
      }, {
        label: 'Exclude',
        value: 'except'
      }]
    },
    STATUS_ENQUEUED () {
      return STATUS_ENQUEUED
    },
    STATUS_PROCESSING () {
      return STATUS_PROCESSING
    },
    STATUS_CANCELED () {
      return STATUS_CANCELED
    },
    STATUS_ERROR () {
      return STATUS_ERROR
    },
    STATUS_COMPLETE () {
      return STATUS_COMPLETE
    },
    eye () {
      return eye
    },
    check () {
      return check
    },
    plus () {
      return plus
    },
    x () {
      return x
    },
    language () {
      return this.$store.state.ui.language
    },
    dmyhs () {
      return this.$options.filters.dmyhs
    },
    itemRepeat: {
      get () {
        return this.repeatFromItem(this.item)
      },

      set (v) {
        if (!this.item) {
          return
        }

        const x = this.ReportRepeats[v]
        this.$set(this.item, 'repeat', x?.value || '')
        this.$set(this.item, 'rounding', !!x?.rounding)
      }
    },
    itemChannelsArr: {
      get () {
        if (!this.itemChannels) {
          return []
        }

        return Object.keys(this.itemChannels)
      },
      set (val) {
        if (!val) {
          return
        }

        this.itemChannels = val.reduce((acc, v) => {
          if (this.itemChannels[v]) {
            acc[v] = this.itemChannels[v]
            return acc
          }

          acc[v] = ''
          return acc
        }, {})
      }
    },
    itemFilters () {
      if (!this.item) {
        return {
          [AND]: false,
          filters: []
        }
      }

      let filters = this.item.filters?.[AND] || this.item.filters?.[OR]
      filters = filters || []

      const filtersBy = filters.reduce((acc, val) => {
        let values = Object.entries(val)
        if (!values || !values.length) {
          return acc
        }

        const [operator, f] = values[0]

        values = Object.entries(f)
        if (!values || !values.length) {
          return acc
        }

        const [field, value] = values[0]

        const isArray = this.ReportThreatOverviewFields[field]?.array

        acc[field] = {
          operator,
          value: isArray ? value.join(',') : value
        }

        return acc
      }, {})

      const filtersFinal = Object.entries(this.ReportThreatOverviewFields).map(([key, value]) => {
        const f = filtersBy[key]
        if (f) {
          return {
            operator: f.operator,
            field: key,
            value: f.value
          }
        }

        return {
          operator: value.array ? 'intersect' : 'eq',
          field: key,
          value: ''
        }
      })

      return {
        [AND]: !!this.item.filters?.[AND],
        filters: filtersFinal
      }
    },
    itemFiltersAnd: {
      get () {
        return !!this.itemFilters[AND]
      },
      set (and) {
        if (and === this.itemFiltersAnd) {
          return
        }
        if (and) {
          const x = this.item.filters[OR] ? JSON.parse(JSON.stringify(this.item.filters[OR])) : []
          this.$delete(this.item.filters, OR)
          this.$set(this.item.filters, AND, x)
          return
        }

        const x = this.item.filters[AND] ? JSON.parse(JSON.stringify(this.item.filters[AND])) : []
        this.$delete(this.item.filters, AND)
        this.$set(this.item.filters, OR, x)
      }
    },
    itemDates: {
      get () {
        if (!this.item) {
          return []
        }

        const from = this.item.time_from && new Date(this.item.time_from) > 0 ? this.item.time_from : null
        const to = this.item.time_to && new Date(this.item.time_to) > 0 ? this.item.time_to : null

        if (!from && !to) {
          return null
        }

        return [
          from,
          to
        ]
      },
      set (v) {
        if (!v) {
          this.item.time_from = null
          this.item.time_to = null
          return
        }

        const [from, to] = v
        this.item.time_from = from
        this.item.time_to = to
      }
    }
  },
  watch: {
    language (v, o) {
      if (!o) {
        return
      }

      if (!this.$refs.table) {
        return
      }

      this.$nextTick(() => {
        this.$refs.table.loadSlots()
      })
    },
    item (v) {
      if (!v.channels) {
        return
      }

      this.itemChannels = Object.keys(v.channels).reduce((acc, val) => {
        if (!v.channels[val]) {
          return acc
        }

        acc[val] = v.channels[val] instanceof Array ? v.channels[val].join(',') : v.channels[val]
        return acc
      }, {})
    },
    itemChannels: {
      deep: true,
      handler (v) {
        if (!v) {
          return
        }

        this.item.channels = Object.keys(v).reduce((acc, val) => {
          acc[val] = typeof v[val] === 'string' ? v[val].split(',') : v[val]
          return acc
        }, {})
      }
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    show (item) {
      this.step = 1
      this.item = item
      this.modal = true
    },
    create () {
      this.resetItem()
      this.modal = true
    },
    resetItem () {
      this.step = 1
      this.item = {
        name: '',
        template: this.ReportThreatOverview,
        channels: {},
        time_from: null,
        time_to: null,
        filters: {},
        repeat: '',
        rounding: false,
        scheduled_time: '09:00',
        scheduled_weekday: 1,
        scheduled_day: 1,
        options: {
          limit: 10
        }
      }

      this.itemChannels = {}
    },
    repeatFromItem (item) {
      if (!item) {
        return 'no'
      }

      if (item.repeat === REPEAT_DAILY) {
        if (item.rounding) {
          return 'dailyRound'
        }

        return 'daily'
      }

      if (item.repeat === REPEAT_WEEKLY) {
        if (item.rounding) {
          return 'weeklyRound'
        }

        return 'weekly'
      }

      if (item.repeat === REPEAT_MONTHLY) {
        if (item.rounding) {
          return 'monthlyRound'
        }

        return 'monthly'
      }

      return 'no'
    },
    updateFilter (filter, value) {
      if (!this.item) {
        return
      }

      const filters = this.itemFilters.filters
        .map(f => ({
          operator: f.operator,
          field: f.field,
          value: f.field === filter.field ? value : f.value
        }))
        .filter(f => !!f.value)
        .map(f => {
          const isArray = this.ReportThreatOverviewFields[f.field]?.array

          return {
            [f.operator]: {
              [f.field]: isArray ? f.value.split(',') : f.value
            }
          }
        })

      if (!this.item.filters) {
        this.$set(this.item, 'filters', {
          [OR]: filters
        })
        return
      }

      this.$set(this.item.filters, this.itemFiltersAnd ? AND : OR, filters)
    },
    submit () {
      this.store()
    },
    store () {
      if (!this.item) {
        return
      }

      return this.$http.post('/api/v1/reports', this.item)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Report Created'
          })

          this.modal = false
          this.resetItem()
        })
    },
    destroy (x) {
      return this.$http.delete(`/api/v1/reports/${x.id}`)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: this.$t('reports.reportDeleted')
          })
        })
    },
    items (filter, sort, pagination) {
      return this.$http.get(`/api/v1/reports?page=${pagination.page}&limit=${pagination.perPage}&query=${filter.query}&sort=${sort.by}&order=${sort.order}`)
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0) {
            return {
              total: 0,
              items: []
            }
          }

          return data.data
        })
    }
  }
}
</script>
