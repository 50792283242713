<template>
  <section class="section">
    <div class="level">
      <h2 class="title level-left">
        {{ $t('route.forwarders') }}
      </h2>
      <p>
        <button
          class="button is-primary"
          @click.prevent="create"
        >
          <octicon :icon="plus" /> <span>{{ $t('create') }}</span>
        </button>
      </p>
    </div>

    <box>
      <data-table
        ref="table"
        :items="items"
        :theme="$_ui_theme_tables"
      >
        <data-column
          field="addr"
          :label="$t('addr')"
        />
        <data-column
          field="event_format"
          :label="$t('event_format')"
        />
        <data-column
          field="authenticator_type"
          :label="$t('authenticator_type')"
        />
        <data-column
          field="authenticator_name"
          :label="$t('authenticator_name')"
        />
        <data-column
          field="authenticator_value"
          :label="$t('authenticator_value')"
        />
        <data-column
          field="toggle"
          :label="$t('toggle')"
        >
          <template slot-scope="props">
            <input
              :id="props.item.id" v-model="props.item.toggle" type="checkbox"
              :name="props.item.id" class="switch"
              disabled
            >
            <label :for="props.item.id" class="is-capitalized" />
          </template>
        </data-column>
        <data-column
          :label="$t('actions')"
          :sortable="false"
        >
          <template slot-scope="props">
            <button
              v-show="can(`update:forwarders:${props.item.id}`)"
              class="button is-small is-warning is-light mr-2"
              @click.prevent="edit(props.item)"
            >
              <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
            </button>

            <button
              v-show="can(`update:forwarders:${props.item.id}`)"
              class="button is-small is-danger is-light"
              @click.prevent="destroy(props.item)"
            >
              <octicon :icon="trashcan" /> <span>{{ $t('delete') }}</span>
            </button>
          </template>
        </data-column>
      </data-table>
    </box>

    <modal v-if="item" :show.sync="modal" content-width="800px">
      <form @submit.prevent="submit">
        <box>
          <div slot="header">
            <div class="is-flex is-justify-content-space-between">
              <div>{{ $t('newForwarder') }}</div>
              <div>
                <div class="field-2">
                  <input
                    id="forwarder-toggle" v-model="item.toggle" type="checkbox"
                    name="forwarder-toggle" class="switch"
                  >
                  <label for="forwarder-toggle" class="is-capitalized" />
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t('type') }}</label>
            <div class="select">
              <select v-model="item.type">
                <option v-for="t in ForwarderTypes" :key="t.value" :value="t.value">
                  {{ t.label }}
                </option>
              </select>
            </div>
          </div>

          <template v-if="item.type === ForwarderSIEM">
            <div class="field">
              <label class="label">{{ $t('addr') }}</label>
              <div class="control">
                <input
                  v-model="item.addr" class="input" type="text"
                  required placeholder="https://siem.cyradar.com:8643/event"
                >
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('event_format') }}</label>
              <div class="select">
                <select v-model="item.event_format">
                  <option v-for="t in EventFormats" :key="t.value" :value="t.value">
                    {{ t.label }}
                  </option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('authenticator_type') }}</label>
              <div class="select">
                <select v-model="item.authenticator_type">
                  <option v-for="t in AuthenticatorTypes" :key="t.value" :value="t.value">
                    {{ t.label }}
                  </option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('authenticator_name') }}</label>
              <div class="control">
                <input
                  v-model="item.authenticator_name" class="input" type="text"
                  required placeholder="Authorization"
                >
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('authenticator_value') }}</label>
              <div class="control">
                <input
                  v-model="item.authenticator_value" class="input" type="text"
                  required
                >
              </div>
            </div>
          </template>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </box>
      </form>
    </modal>
  </section>
</template>

<script>
import { DataTable, DataColumn } from 'vue-teible'
import { pencil, plus, trashcan } from 'octicons-vue'
import { Modal } from '@cyradar/ui'
import { ForwarderSIEM } from '@/store'

export default {
  components: { DataTable, DataColumn, Modal },
  data () {
    return {
      modal: false,
      item: {
        type: ForwarderSIEM,
        event_format: 'JSON',
        authenticator_type: 'HEADER',
        authenticator_name: 'Authorization',
        toggle: true
      }
    }
  },
  computed: {
    ForwarderSIEM () {
      return ForwarderSIEM
    },
    ForwarderTypes () {
      return [{
        label: 'SIEM Forwarder',
        value: this.ForwarderSIEM
      }]
    },
    AuthenticatorTypes () {
      return [{
        label: 'Header',
        value: 'HEADER'
      }, {
        label: 'Cookie',
        value: 'COOKIE'
      }]
    },
    EventFormats () {
      return [{
        label: 'JSON',
        value: 'JSON'
      }, {
        label: 'XML',
        value: 'XML'
      }, {
        label: 'CEF',
        value: 'CEF'
      }, {
        label: 'LEEF',
        value: 'LEEF'
      }]
    },
    plus () {
      return plus
    },
    trashcan () {
      return trashcan
    },
    pencil () {
      return pencil
    },
    language () {
      return this.$store.state.ui.language
    }
  },
  watch: {
    language (v, o) {
      if (!o) {
        return
      }

      if (!this.$refs.table) {
        return
      }

      this.$nextTick(() => {
        this.$refs.table.loadSlots()
      })
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    submit () {
      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    resetItem () {
      this.item = {
        type: this.ForwarderSIEM,
        event_format: 'JSON',
        authenticator_type: 'HEADER',
        authenticator_name: 'Authorization',
        toggle: true
      }
    },
    create () {
      this.resetItem()
      this.modal = true
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
    },
    store () {
      if (!this.item) {
        return
      }

      return this.$http.post('/api/v1/forwarder-config', this.item)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Setting Created'
          })

          this.modal = false
          this.resetItem()
        })
    },
    update () {
      if (!this.item) {
        return
      }

      return this.$http.patch(`/api/v1/forwarder-config/${this.item.id}`, this.item)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Setting Updated'
          })

          this.modal = false
          this.resetItem()
        })
    },
    destroy (x) {
      return this.$http.delete(`/api/v1/forwarder-config/${x.id}`)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Setting Deleted'
          })
        })
    },
    items (filter, sort, pagination) {
      return this.$http.get(`/api/v1/forwarder-config?page=${pagination.page}&limit=${pagination.perPage}&query=${filter.query}&sort=${sort.by}&order=${sort.order}`)
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0 || !data.data.items || data.data.items.length === 0) {
            return {
              total: 0,
              items: []
            }
          }

          return {
            total: data.data.total,
            items: data.data.items.map(item => ({ ...item, type: ForwarderSIEM }))
          }
        })
    }
  }
}
</script>
<style lang="scss">
.control-confidence {
  position: relative;

  .button {
    position: absolute;
    top: 0.4em;
    right: 3em;
  }
}
</style>
